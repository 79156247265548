/* This example requires Tailwind CSS v2.0+ */

import React from "react";


import Header from "../components/Header/Header";

import Intro from "../page-sections/Thank/Intro";
import Body from "../page-sections/Thank/Body";
import Footer from "../components/Footer/Footer";
import SEO from "../components/SEO/Thank/SEO";

export default function Example() {
  return (
    <div>
      <Header />
      <SEO/>
      <Intro />
      <Body />
      <Footer />
    </div>
  );
}
